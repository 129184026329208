import { getContentFromQuery } from '../../lib/contentful';
import { getPageProps } from '../../lib/propResolver';
import { Layout } from '../../components';
import { getComponent } from '../../lib/ComponentFactory';
import { getAvailableLocales, isLocalePermitted, upperCaseLocale } from '../../lib/utils';
import PageContext from '../../components/PageContext';
import { contentTypes } from '../../siteConfig';
import keyBy from 'lodash/keyBy';
import { localeHasSearch } from '../../lib/utils';

/**
 * Page from a brand given a locale
 * @param {object} [props] Component props
 * @param {object} [props.pageData] data retrieved from contentful
 * @param {object} [props.globalData] global data
 * @param {string} [props.locale] locale being used
 * @param {object} [props.localesData] locales data
 * @param {string} [props.className] class to be used in layout
 * @returns {JSX.Element} page from slug
 */
export default function Page({ pageData, globalData, locale, localesData, className }) {
  return (
    pageData && (
      <PageContext.Provider
        value={{
          templateEntryId: pageData.sys.id,
          BvId: pageData?.fields?.externalIDs?.websiteReviewsId || null,
        }}>
        <Layout
          title={pageData.fields.title || pageData.fields.productName}
          brandSlug={`/${pageData.fields.brand.fields.slug}`}
          globalData={globalData}
          seoData={pageData.fields.seoData}
          className={className}
          locale={locale}
          localesData={localesData}>
          {getComponent(pageData.sys.contentType.sys.id, {
            fields: pageData.fields,
          })}
        </Layout>
      </PageContext.Provider>
    )
  );
}

/**
 *  method from next js to retrieve data
 *
 * @private
 */
export async function getStaticProps({ params, preview = false }) {
  const brand = process.env.BRAND;
  const slug = params.slug.join('/'); // Join the slug array back to a string
  const pageProps = await getPageProps({ ...params, slug }, brand, preview);
  if ((slug === '404' || slug === '500') && pageProps?.props?.pageData?.fields) {
    pageProps.props.pageData.fields.errorPage = true;
  }

  return pageProps;
}

/**
 *  method from next js to set paths
 *
 * @private
 */
export async function getStaticPaths() {
  const brand = process.env.BRAND;
  const brandData = await getContentFromQuery({
    content_type: 'brand',
    'fields.slug': brand,
  });

  const availableLocales = getAvailableLocales(brandData);

  const masterList = [];
  const blacklistedIds = brandData[0]?.fields?.overrideHomepage;

  for (const contentType of contentTypes) {
    // get all pages for given content type
    const pages = await getContentFromQuery({
      content_type: contentType,
      locale: '*',
      'fields.brand.sys.contentType.sys.id': 'brand',
      'fields.brand.fields.slug': brand,
      include: 1,
      select: 'fields.availability',
      limit: 1000,
      'sys.id[nin]': blacklistedIds,
    });

    // get all localized slugs for given content type
    const slugs = {};
    for (const locale of availableLocales) {
      if (contentType === 'searchResultsPage' && !localeHasSearch(locale)) {
        console.warn(
          `Skipping searchResultsPage for ${locale}: search is not configured for this locale`,
        );
        continue;
      }
      const localeResult = await getContentFromQuery({
        content_type: contentType,
        locale: upperCaseLocale(locale),
        'fields.brand.sys.contentType.sys.id': 'brand',
        'fields.brand.fields.slug': brand,
        include: 1,
        select: 'fields.slug',
        limit: 1000,
      });
      const result = localeResult.map((result) => {
        return { id: result.sys?.id, slug: result.fields.slug, contentType: contentType };
      });
      slugs[locale] = keyBy(result, 'id');
    }

    pages.forEach((page) => {
      if (page?.fields?.availability) {
        Object.entries(page.fields.availability).forEach((entry) => {
          const locale = entry[0].toLowerCase();
          if (
            availableLocales.includes(locale) &&
            entry[1] &&
            entry[1] !== 'inactive' &&
            isLocalePermitted(locale)
          ) {
            // this locale is active for this page, find corresponding slug
            const slug = slugs?.[locale]?.[page.sys?.id]?.slug;
            if (slug) {
              masterList.push({ params: { locale: locale, slug: slug.split('/') } });
            }
          }
        });
      }
    });
  }
  return {
    paths: masterList,
    fallback: false,
  };
}
